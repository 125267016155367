import { FaMedal } from 'react-icons/fa';
import { FaBalanceScale } from 'react-icons/fa';
import { FaLightbulb } from 'react-icons/fa';
import { FaHeart } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa';


function WhyUs() {
    return (
        <>
            <section className="py-10">
                <div className="bg-holder bg-size" style={{ backgroundImage: 'url(assets/img/gallery/about-bg.png)', backgroundPosition: 'top center', backgroundSize: 'contain' }}>
                </div>
                {/*/.bg-holder*/}

                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-lg-1 mb-5 mb-lg-0"><img className="fit-cover rounded-circle w-100" src="assets/img/gallery/health-care.png" alt="..." /></div>
                        <div className="col-md-6 text-center text-md-start">
                            <h2 className="fw-bold mb-4">About Truelife Specialty Hospital</h2>
                            <p>Truelife Specialty Hospital is a renowned healthcare facility located in the heart of Al Ain, United Arab Emirates. Since our establishment, we have been committed to providing exceptional medical care while prioritizing the well-being and comfort of our patients.</p>
                            <h2 className="fw-bold mb-4">Our Mission</h2>
                            <p>At Truelife Specialty Hospital, our mission is to deliver comprehensive, compassionate, and personalized medical care across a wide range of specialties. We aim to be the preferred healthcare destination for individuals and families in Al Ain and beyond, ensuring that every patient receives the highest standard of treatment and attention.</p>
                            <h2 className="fw-bold mb-4">Our Vision</h2>
                            <p>Our vision is to be recognized as a leading healthcare institution that consistently exceeds the expectations of our patients by delivering outstanding medical services, fostering a culture of continuous improvement, and embracing the latest advancements in the field of healthcare</p>
                            <h2 className="fw-bold mb-4">Our Facilities</h2>
                            <p>Our hospital is equipped with state-of-the-art medical facilities and technologies, ensuring that our patients have access to the latest advancements in healthcare. From advanced diagnostic equipment to modern treatment modalities, we are committed to providing our patients with the best possible care.</p>
                            <h2 className="fw-bold mb-4">Our Commitment</h2>
                            <p>Truelife Specialty Hospital is dedicated to providing a patient-centered approach to healthcare. We prioritize the needs of our patients and strive to create a supportive and healing environment for all individuals who walk through our doors. Our team of healthcare professionals is committed to upholding the highest standards of medical ethics and professionalism while delivering personalized care to each patient.</p>

                        </div>
                    </div>
                </div>
            </section>
            <section className="py-0">
                <div className="container">
                    <div className="row py-5 align-items-center justify-content-center justify-content-lg-evenly" style={{ textAlign: "center" }}>
                        <h2 className="fw-bold mb-4">Our Values</h2>

                        <div className="col-auto col-md-2 text-xl-start">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-box text-center">
                                    <a className="text-decoration-none" href="#!">
                                        <FaMedal size={50} />
                                        <p className="fs-1 fs-xxl-2 text-center">Excellence</p>
                                        <p>We are committed to delivering excellence in all aspects of patient care, treatment, and service delivery.</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto col-md-2 text-xl-start">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-box text-center">
                                    <a className="text-decoration-none" href="#!">
                                        <FaBalanceScale size={50} />
                                        <p className="fs-1 fs-xxl-2 text-center">Integrity</p>
                                        <p>We uphold the highest ethical standards, transparency, and honesty in all our interactions.</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto col-md-2 text-xl-start">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-box text-center">
                                    <a className="text-decoration-none" href="#!">
                                        <FaLightbulb size={50} />
                                        <p className="fs-1 fs-xxl-2 text-center">Innovation</p>
                                        <p>We embrace innovation and continuously seek new and improved ways to enhance patient care and outcomes.</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto col-md-2 text-xl-start">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-box text-center">
                                    <a className="text-decoration-none" href="#!">
                                        <FaHeart size={50} />
                                        <p className="fs-1 fs-xxl-2 text-center">Compassion</p>
                                        <p>We approach healthcare with empathy, understanding, and a genuine concern for the well-being of our patients.</p>
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="col-auto col-md-2 text-xl-start">
                            <div className="d-flex flex-column align-items-center">
                                <div className="icon-box text-center">
                                    <a className="text-decoration-none" href="#!">
                                        <FaUsers  size={50} />
                                        <p className="fs-1 fs-xxl-2 text-center">Collaboration</p>
                                        <p>We foster a collaborative and multidisciplinary approach to healthcare, working together to achieve the best results for our patients.</p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )

}
export default WhyUs