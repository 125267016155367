import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Appointment from '../Appointment/Appointment';

function NavBar() {

    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);

    return (
        <nav style={{backgroundColor: "white"}} className="navbar navbar-expand-lg navbar-light fixed-top py-3 d-block" data-navbar-on-scroll="data-navbar-on-scroll" >
            <Appointment show={showAppointment} handleClose={handleCloseAppointment}  />

            <div className="container">
                <Link className="navbar-brand" to="/">
                    <img src="TrueLife.png" width="118" alt="logo" />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"> </span>
                </button>
                <div className="collapse navbar-collapse border-top border-lg-0 mt-4 mt-lg-0" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto pt-2 pt-lg-0 font-base">
                        <li className="nav-item px-2">
                            <Link className="nav-link" aria-current="page" to="/">Home</Link>
                        </li>
                        <li className="nav-item px-2">
                            <Link className="nav-link" to="/about" >About Us</Link>
                        </li>
                        <li className="nav-item px-2">
                            <Link className="nav-link" to="/specialities">Specialities</Link>
                        </li>
                        <li className="nav-item px-2">
                            <Link className="nav-link" to="/Doctors">Doctors</Link>
                        </li>
                        <li className="nav-item px-2">
                            <Link className="nav-link" to="/contact">Contact</Link>
                        </li>
                        
                        {/* <li className="nav-item px-2">
                            <a className="nav-link" href="#findUs">Contact</a>
                        </li> */}
                    </ul>
                    <a onClick={handleShowAppointment} className="btn btn-sm btn-outline-primary rounded-pill order-1 order-lg-0 ms-lg-4" href="#!">Make an appointment</a>
                </div>
            </div>
        </nav>
    )
}
export default NavBar