import Slider from "../Slider/Slider"
import Info from "../Info/Info"
import SpecialitiesHome from "../SpecialitiesHome/SpecialitiesHome"

function Home() {
    return (
        <>
            <Slider />
            <Info />
            <SpecialitiesHome />
        </>

    )

}
export default Home