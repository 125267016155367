function Slider() {
    return (
        <section className="py-xxl-10 pb-0" id="home">
            <div className="bg-holder bg-size" style={{ backgroundImage: 'url(assets/img/gallery/hero-bg.png)', backgroundPosition: 'top center', backgroundSize: 'cover' }}>
            </div>
            {/* /.bg-holder */}

            <div className="container">
                <div className="row min-vh-xl-100 min-vh-xxl-25">
                    <div className="col-md-5 col-xl-6 col-xxl-7 order-0 order-md-1 text-end"><img className="pt-7 pt-md-0 w-100" src="assets/img/gallery/hero.png" alt="hero-header" /></div>
                    <div className="col-md-7 col-xl-6 col-xxl-5 text-md-start text-center py-6">
                        <h1 className="fw-light font-base fs-6 fs-xxl-7">Welcome to <strong>Truelife Specialty Hospital </strong><br />- Al Ain, UAE</h1>
                        <p className="fs-1 mb-5">Welcome to Truelife Specialty Hospital, a leading healthcare institution located in Al Ain, United Arab Emirates. We are dedicated to providing exceptional medical care across a wide range of specialties, ensuring the well-being and health of our patients.</p>
                    </div>
                </div>
            </div>
        </section>
    )

}
export default Slider