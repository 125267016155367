import { FaChild, FaEye, FaXRay, FaStethoscope, FaTooth, FaBone, FaUserMd, FaRunning, FaHeart, FaBrain, FaDeaf, FaSmile, FaHome, FaLungs, FaFemale, FaUserInjured, FaDumbbell, FaCapsules, FaTeethOpen, FaUtensils, FaRadiation, FaHandSparkles } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {

    const specialities = [
        { title: "Pediatrics", icon: FaChild },
        { title: "Ophthalmology", icon: FaEye },
        { title: "Radiology", icon: FaXRay },
        { title: "Gastroenterology", icon: FaStethoscope },
        { title: "Urology", icon: FaTooth },
        { title: "Orthopedic Surgery", icon: FaBone },
        { title: "General Surgery", icon: FaUserMd },
        { title: "Sports Medicine", icon: FaRunning },
        { title: "Cardiology", icon: FaHeart },
        { title: "Neurology", icon: FaBrain },
        { title: "Otolaryngology", icon: FaDeaf },
        { title: "Dermatology", icon: FaSmile },
        { title: "Family Medicine", icon: FaHome },
        { title: "Internal Medicine", icon: FaLungs },
        { title: "Pulmonology", icon: FaLungs },
        { title: "Obstetrics and Gynecology", icon: FaFemale },
        { title: "Psychiatry", icon: FaUserInjured },
        { title: "General Practitioner", icon: FaUserMd },
        { title: "Dentist", icon: FaTooth },
        { title: "Physiotherapist", icon: FaDumbbell },
        { title: "Prosthodontics", icon: FaCapsules },
        { title: "Pediatric Dentistry", icon: FaTeethOpen },
        { title: "Clinical Dietician", icon: FaUtensils },
        { title: "Radiography Technician", icon: FaRadiation },
        { title: "Rheumatology", icon: FaHandSparkles }
    ];

    return (
        <>
            <section className="py-0 bg-secondary">
                <div className="bg-holder opacity-25" style={{ backgroundImage: 'url(assets/img/gallery/dot-bg.png)', backgroundPosition: 'top left', marginTop: '-3.125rem', backgroundSize: 'auto' }}></div>
                {/*/.bg-holder*/}

                <div className="container">
                    <div className="row py-8">
                        <div className="col-12 col-sm-12 col-lg-6 mb-4 order-0 order-sm-0">
                            <a className="text-decoration-none" href="#"><img src="TrueLife.png" width="150" height="150" alt="" /></a>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-2 mb-3 order-2 order-sm-1">
                            <h5 className="lh-lg fw-bold mb-4 text-light font-sans-serif">Quick Links</h5>
                            <ul className="list-unstyled mb-md-4 mb-lg-0">
                     

                                <li className="lh-lg">
                                    <Link className="footer-link" aria-current="page" to="/">Home</Link>
                                </li>
                                <li className="lh-lg">
                                    <Link className="footer-link" to="/about" >About Us</Link>
                                </li>
                                <li className="lh-lg">
                                    <Link className="footer-link" to="/specialities">Specialities</Link>
                                </li>
                                <li className="lh-lg">
                                    <Link className="footer-link" to="/Doctors">Doctors</Link>
                                </li>
                                <li className="lh-lg">
                                    <Link className="footer-link" to="/contact">Contact</Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
                            <h5 className="lh-lg fw-bold text-light mb-4 font-sans-serif">Number:</h5>
                            <h5>03 701 1888</h5>
                            <h5 className="lh-lg fw-bold text-light mb-4 font-sans-serif">Location:</h5>
                            <h5>Industrial Area - Al Ain - Abu Dhabi</h5>

                        </div>
                        {/* <div className="col-6 col-sm-4 col-lg-2 mb-3 order-3 order-sm-2">
                            <h5 className="lh-lg fw-bold text-light mb-4 font-sans-serif">Customer Care</h5>
                            <ul className="list-unstyled mb-md-4 mb-lg-0">
                                <li className="lh-lg"><a className="footer-link" href="#!">About Us</a></li>
                                <li className="lh-lg"><a className="footer-link" href="#!">Contact Us</a></li>
                                <li className="lh-lg"><a className="footer-link" href="#!">Get Updates</a></li>
                            </ul>
                        </div> */}
                    </div>
                </div>
            </section>

            {/* ============================================*/}
            {/* <section> begin ============================*/}
            <section className="py-0 bg-primary">

                <div className="container">
                    <div className="row justify-content-md-between justify-content-evenly py-4">
                        <div className="col-12 col-sm-8 col-md-6 col-lg-auto text-center text-md-start">
                            <p className="fs--1 my-2 fw-bold text-200">All rights Reserved &copy; True Life Speciality Hospital, 2024</p>
                        </div>
                        <div className="col-12 col-sm-8 col-md-6">

                        </div>
                    </div>
                </div>
                {/* end of .container*/}

            </section>
        </>
    )
}
export default Footer