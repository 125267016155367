import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import NavBar from './Components/NavBar/NavBar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState } from 'react';
import AboutUs from './Components/AboutUs/AboutUs';
import Contact from './Components/Contact/Contact';
import SpecialitiesPage from './Components/SpecialitiesPage/SpecialitiesPage';
import Doctors from './Components/Doctors/Doctors';
import Footer from './Components/Footer/Footer';
function App() {
  const [activeComponent, setActiveComponent] = useState('home');

  const handleComponentChange = (component) => {

    console.log(component)
    setActiveComponent(component);
    
    window.scrollTo(0,0)
    window.history.pushState({}, null, `/${component}`);
  };

  return (
    <>
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home onComponentChange={handleComponentChange} />} />
          <Route path="/about" element={<AboutUs onComponentChange={handleComponentChange} />} />
          <Route path="/contact" element={<Contact onComponentChange={handleComponentChange} />} />
          <Route path="/Specialities" element={<SpecialitiesPage onComponentChange={handleComponentChange} />} />
          <Route path="/Doctors" element={<Doctors onComponentChange={handleComponentChange} />} />



        </Routes>
        <Footer />
      </Router>

    </>
  );
}

export default App;
