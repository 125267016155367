function Contact() {
    return (
        <section className="py-10">
            <div className="container">
                <div className="row">
                    <div className="bg-holder bg-size" style={{ backgroundImage: 'url(assets/img/gallery/dot-bg.png)', backgroundPosition: 'bottom right', backgroundSize: 'auto' }}>
                    </div>
                    {/*/.bg-holder*/}
                    <div className="col-lg-6 z-index-2 mb-5"><img className="w-100" src="assets/img/gallery/appointment.png" alt="..." /></div>
                    <div className="col-lg-6 z-index-2">
                        <form className="row g-3">
                            <div className="col-md-6">
                                <label className="visually-hidden" htmlFor="inputName">Name</label>
                                <input className="form-control form-livedoc-control" id="inputName" type="text" placeholder="Name" />
                            </div>
                            <div className="col-md-6">
                                <label className="visually-hidden" htmlFor="inputPhone">Phone</label>
                                <input className="form-control form-livedoc-control" id="inputPhone" type="text" placeholder="Phone" />
                            </div>
                            <div className="col-md-6">
                                <label className="form-label visually-hidden" htmlFor="inputCategory">Category</label>
                                <select className="form-select" id="inputCategory">
                                    <option selected="selected">Category</option>
                                    <option>Category One</option>
                                    <option>Category Two</option>
                                    <option>Category Three</option>
                                </select>
                            </div>
                            <div className="col-md-6">
                                <label className="form-label visually-hidden" htmlFor="inputEmail">Email</label>
                                <input className="form-control form-livedoc-control" id="inputEmail" type="email" placeholder="Email" />
                            </div>
                            <div className="col-md-12">
                                <label className="form-label visually-hidden" htmlFor="validationTextarea">Message</label>
                                <textarea className="form-control form-livedoc-control" id="validationTextarea" placeholder="Message" style={{ height: '250px' }} required="required"></textarea>
                            </div>
                            <div className="col-12">
                                <div className="d-grid">
                                    <button className="btn btn-primary rounded-pill" type="submit">Sign in</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row" style={{marginTop: "50px"}}>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3639.114019647143!2d55.7447281856619!3d24.202785966107243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e8ab17892a52c0f%3A0x575ef3facc20f1c3!2sTRUE%20LIFE%20speciality%20hospital!5e0!3m2!1sen!2sae!4v1711353328668!5m2!1sen!2sae" width="100%" height="450" ></iframe>
                </div>
            </div>
        </section>
    )
}
export default Contact