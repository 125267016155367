import * as icons from "react-icons/fa";
import { FaChild, FaEye, FaXRay, FaStethoscope, FaTooth, FaBone, FaUserMd, FaRunning, FaHeart, FaBrain, FaDeaf, FaSmile, FaHome, FaLungs, FaFemale, FaUserInjured, FaDumbbell, FaCapsules, FaTeethOpen, FaUtensils, FaRadiation, FaHandSparkles   } from 'react-icons/fa';


function SpecialitiesHome() {

    const specialities = [
        { title: "Pediatrics", icon: FaChild },
        { title: "Ophthalmology", icon: FaEye },
        { title: "Radiology", icon: FaXRay },
        { title: "Gastroenterology", icon: FaStethoscope },
        { title: "Urology", icon: FaTooth },
        { title: "Orthopedic Surgery", icon: FaBone },
        { title: "General Surgery", icon: FaUserMd },
        { title: "Sports Medicine", icon: FaRunning },
        { title: "Cardiology", icon: FaHeart },
        { title: "Neurology", icon: FaBrain },
        { title: "Otolaryngology", icon: FaDeaf },
        { title: "Dermatology", icon: FaSmile },
        { title: "Family Medicine", icon: FaHome },
        { title: "Internal Medicine", icon: FaLungs },
        { title: "Pulmonology", icon: FaLungs },
        { title: "Obstetrics and Gynecology", icon: FaFemale },
        { title: "Psychiatry", icon: FaUserInjured },
        { title: "General Practitioner", icon: FaUserMd },
        { title: "Dentist", icon: FaTooth },
        { title: "Physiotherapist", icon: FaDumbbell },
        { title: "Prosthodontics", icon: FaCapsules },
        { title: "Pediatric Dentistry", icon: FaTeethOpen },
        { title: "Clinical Dietician", icon: FaUtensils },
        { title: "Radiography Technician", icon: FaRadiation },
        { title: "Rheumatology", icon: FaHandSparkles   }
    ];
    return (
        <>
            <section className="py-5" id="departments">
                <div className="container">
                    <div className="row">
                        <div className="col-12 py-3">
                            <div className="bg-holder bg-size" style={{ backgroundImage: 'url(assets/img/gallery/bg-departments.png)', backgroundPosition: 'top center', backgroundSize: 'contain' }}>
                            </div>
                            <h1 className="text-center">OUR DEPARTMENTS</h1>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-0">
                <div className="container">
                    <div className="row py-5 align-items-center justify-content-center justify-content-lg-evenly">
                        {specialities.map((s, index) => (
                            <div className="col-auto col-md-2 text-xl-start" key={index}>
                                <div className="d-flex flex-column align-items-center">
                                    <div className="icon-box text-center">
                                        <a className="text-decoration-none" href="#!">
                                            <s.icon  size={35} />
                                            <p className="fs-1 fs-xxl-2 text-center">{s.title}</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )

}
export default SpecialitiesHome