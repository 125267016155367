import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import React, { useState, useEffect } from 'react';

function SpecialitiesPage() {
    const [showAppointment, setShowAppointment] = useState(false);
    const handleCloseAppointment = () => setShowAppointment(false);
    const handleShowAppointment = () => setShowAppointment(true);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


        const specialities = [
        {
            title: "Pediatrics",
            desc: "Pediatrics is a medical specialty focused on the healthcare of infants, children, and adolescents. Our pediatric department at Truelife Specialty Hospital is dedicated to providing comprehensive care for children, including routine check-ups, vaccinations, and treatment for various childhood illnesses."
        },
        {
            title: "Ophthalmology",
            desc: "Ophthalmology specializes in the diagnosis and treatment of eye disorders and diseases. Our ophthalmology department offers a wide range of services, including eye examinations, vision correction, cataract surgery, and treatment for eye conditions such as glaucoma and retinal disorders."
        },
        {
            title: "Radiology",
            desc: "Radiology involves the use of medical imaging techniques to diagnose and treat diseases. At Truelife Specialty Hospital, our radiology department is equipped with advanced imaging technology, including X-rays, CT scans, MRI, and ultrasound, to aid in the accurate diagnosis of various medical conditions."
        },
        {
            title: "Gastroenterology",
            desc: "Gastroenterology focuses on the diagnosis and treatment of disorders of the digestive system. Our gastroenterology department provides comprehensive care for conditions such as acid reflux, inflammatory bowel disease, liver diseases, and gastrointestinal cancers."
        },
        {
            title: "Urology",
            desc: "Urology specializes in the treatment of conditions related to the urinary tract and male reproductive system. Our urology department offers services for urinary tract infections, kidney stones, prostate disorders, and male infertility."
        },
        {
            title: "Orthopedic Surgery",
            desc: "Orthopedic surgery deals with the correction of musculoskeletal injuries and disorders. Our orthopedic department provides a range of services, including joint replacement surgeries, fracture care, sports injuries treatment, and spinal surgery."
        },
        {
            title: "General Surgery",
            desc: "General surgery encompasses a broad range of surgical procedures to treat various conditions. At Truelife Specialty Hospital, our general surgery department offers expertise in abdominal surgery, hernia repair, gallbladder removal, and other surgical interventions."
        },
        {
            title: "Sports Medicine",
            desc: "Sports medicine focuses on the treatment and prevention of sports-related injuries and conditions. Our sports medicine specialists provide comprehensive care for athletes, including injury management, rehabilitation, and performance enhancement."
        },
        {
            title: "Cardiology",
            desc: "Cardiology specializes in the diagnosis and treatment of heart and blood vessel disorders. Our cardiology department offers a range of services, including cardiac screenings, management of heart diseases, interventional cardiology procedures, and heart rhythm management."
        },
        {
            title: "Neurology",
            desc: "Neurology deals with the diagnosis and treatment of disorders affecting the nervous system. Our neurology department provides expertise in conditions such as stroke, epilepsy, multiple sclerosis, and Parkinson's disease."
        },
        {
            title: "Otolaryngology",
            desc: "Otolaryngology, also known as ENT (ear, nose, and throat) medicine, focuses on the diagnosis and treatment of disorders of the head and neck. Our otolaryngology department offers services for conditions such as sinusitis, hearing loss, thyroid disorders, and head and neck cancers."
        },
        {
            title: "Dermatology",
            desc: "Dermatology specializes in the diagnosis and treatment of skin, hair, and nail disorders. Our dermatology department provides services for conditions such as acne, eczema, psoriasis, skin cancer, and cosmetic dermatology procedures."
        },
        {
            title: "Family Medicine",
            desc: "Family medicine emphasizes comprehensive healthcare for individuals and families of all ages. Our family medicine practitioners offer a broad range of services, including preventive care, chronic disease management, and health promotion."
        },
        {
            title: "Internal Medicine",
            desc: "Internal medicine focuses on the diagnosis and treatment of adult diseases. Our internal medicine department provides expertise in managing complex medical conditions, chronic diseases, and preventive care for adults."
        },
        {
            title: "Pulmonology",
            desc: "Pulmonology specializes in the diagnosis and treatment of respiratory disorders. Our pulmonology department offers services for conditions such as asthma, COPD, pulmonary infections, and sleep-related breathing disorders."
        },
        {
            title: "Obstetrics and Gynecology",
            desc: "Obstetrics and gynecology focus on women's reproductive health and pregnancy care. Our obstetrics and gynecology department provides comprehensive maternity care, gynecological services, and reproductive health management."
        },
        {
            title: "Psychiatry",
            desc: "Psychiatry deals with the diagnosis and treatment of mental health disorders. Our psychiatry department offers a range of services for conditions such as depression, anxiety, bipolar disorder, and schizophrenia."
        },
        {
            title: "General Practitioner",
            desc: "General practitioners provide primary healthcare services for individuals of all ages. Our general practitioners offer preventive care, diagnosis and treatment of common illnesses, and management of chronic conditions."
        },
        {
            title: "Dentist",
            desc: "Dentistry focuses on the diagnosis and treatment of oral health issues. Our dental department provides a wide range of services, including preventive care, restorative dentistry, oral surgery, and cosmetic dental procedures."
        },
        {
            title: "Physiotherapist",
            desc: "Physiotherapy involves the treatment of physical injuries and disorders through rehabilitation exercises and techniques. Our physiotherapy department offers personalized treatment plans for musculoskeletal conditions, sports injuries, and post-operative rehabilitation."
        },
        {
            title: "Prosthodontics",
            desc: "Prosthodontics specializes in the restoration and replacement of missing teeth. Our prosthodontics department offers expertise in dental implants, crowns, bridges, and dentures to restore oral function and aesthetics."
        },
        {
            title: "Pediatric Dentistry",
            desc: "Pediatric dentistry focuses on the oral health of children and adolescents. Our pediatric dentistry department provides specialized care for young patients, including preventive treatments, dental restorations, and oral health education."
        },
        {
            title: "Clinical Dietician",
            desc: "Clinical dietitians specialize in providing personalized nutrition therapy for patients with various medical conditions. Our clinical dietitian offers expert guidance on dietary management for conditions such as diabetes, cardiovascular diseases, and gastrointestinal disorders."
        },
        {
            title: "Radiography Technician",
            desc: "Radiography technicians are trained professionals who perform diagnostic imaging procedures. At Truelife Specialty Hospital, our radiography technicians work closely with healthcare providers to ensure accurate and high-quality medical imaging for patient diagnosis and treatment."
        },
        {
            title: "Rheumatology",
            desc: "Rheumatology focuses on the diagnosis and treatment of autoimmune and mus"
        }
    ];

    return (
        <section className="about_section layout_padding" style={{ marginTop: '50px' }}>
            <div className="container">
                <div className="row">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                        <Col sm={3}>
                            <Nav variant="none" className="flex-column">
                                {specialities.map((speciality, index) => (
                                    <Nav.Item key={index}>
                                        <Nav.Link eventKey={index} style={{ color: "black" }}>{speciality.title}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {specialities.map((speciality, index) => (
                                    <Tab.Pane key={index} eventKey={index}>
                                        <img src={speciality.imgSrc} alt={speciality.alt} style={{ width: "100%" }} />
                                        <h1>{speciality.title}</h1>
                                        <h2 style={{ fontWeight: "lighter", fontSize: "20px", color: "black" }}>{speciality.desc}</h2>
                                        {/* <a onClick={() => handleShow()} style={{ backgroundColor: "#dc0976", borderColor: "#dc0976" }} className="btn btn-primary rounded-0 py-4 px-lg-5 d-lg-block">Make an Appointment<i className="fa fa-arrow-right ms-3"></i></a> */}

                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Tab.Container>
                </div>
            </div>
        </section>
    )

}
export default SpecialitiesPage