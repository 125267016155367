function Info() {

    return (
        <section className="bg-secondary">
            <div className="bg-holder" style={{ backgroundImage: 'url(assets/img/gallery/bg-eye-care.png)', backgroundPosition: 'center', backgroundSize: 'contain' }}>
            </div>
            {/* /.bg-holder */}

            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-5 col-xxl-6"><img className="img-fluid" src="assets/img/gallery/eye-care.png" alt="..." /></div>
                    <div className="col-md-7 col-xxl-6 text-center text-md-start">
                        <h2 className="fw-bold text-light mb-4 mt-4 mt-lg-0">Our Commitment to Excellence</h2>
                        <p className="text-light">At Truelife Specialty Hospital, we are committed to providing the highest standard of care and treatment for our patients. Our team of experienced and dedicated healthcare professionals is equipped with the latest medical technologies to ensure accurate diagnosis and effective treatment across all our specialties.</p>
                        {/* <div className="py-3"><a className="btn btn-lg btn-light rounded-pill" href="#!" role="button">Learn more </a></div> */}
                    </div>
                </div>
            </div>
        </section>
    )

}
export default Info