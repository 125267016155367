import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';


function Appointment({ show, handleClose, showPopUp }) {

    const [selectedSpeciality, setSelectedSpeciality] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);

    const handleSpecialityChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedSpeciality(selectedValue);
    };

    const handleLanguageChange = (event) => {
        const selectedValues = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedLanguages(selectedValues);
    };

    const allDoctors = [
        {
            id: "1",
            name: "Dr. Ghaith Abdallah",
            Speciality: "Consultant Pediatrics",
            img: "/doctors/user.png"
        },
        {
            id: "2",
            name: "Dr. Ahmed Gamal",
            Speciality: "Specialist Pediatrics",
            img: "/doctors/gamal.jpeg"
        },
        {
            id: "3",
            name: "Dr. MOHAMMAD NIMER",
            Speciality: "Specialist Pediatrics",
            img: "/doctors/user.png"
        },
        {
            id: "4",
            name: "Dr. Hussain Abu Yousef",
            Speciality: "Specialist Pediatrics",
            img: "/doctors/hussain.jpeg"
        },
        {
            id: "5",
            name: "Dr. SAMEH EL JABER",
            Speciality: "Specialist Pediatrics",
            img: "/doctors/sameh.jpeg"
        },
        {
            id: "6",
            name: "Dr. MOHAMMAD AL DROOS",
            Speciality: "Consultant Ophthalmology",
            img: "/doctors/user.png"
        },
        {
            id: "7",
            name: "Dr. Shikha Nailwal",
            Speciality: "Specialist Ophthalmology",
            img: "/doctors/user.png"
        },
        {
            id: "8",
            name: "Dr. NICOLAS DENOUR",
            Speciality: "Consultant Radiology",
            img: "/doctors/user.png"
        },
        {
            id: "9",
            name: "Dr. Imad Moh'd Tayem",
            Speciality: "Specialist Gastroenterology",
            img: "/doctors/user.png"
        },
        {
            id: "10",
            name: "Dr. Feras Al Badi",
            Speciality: "Specialist Urology",
            img: "/doctors/user.png"
        },
        {
            id: "11",
            name: "Dr. Ziad Nazmat",
            Speciality: "Specialist Orthopedic Surgery",
            img: "/doctors/user.png"
        },
        {
            id: "12",
            name: "Dr. Muoghir Azzouz",
            Speciality: "Specialist Orthopedic Surgery",
            img: "/doctors/user.png"
        },
        {
            id: "13",
            name: "Dr. Hesham Ezzat",
            Speciality: "Specialist Orthopedic Surgery",
            img: "/doctors/user.png"
        },
        {
            id: "14",
            name: "Dr. Vipan Lakshmi",
            Speciality: "Consultant Urology",
            img: "/doctors/user.png"
        },
        {
            id: "15",
            name: "Dr. Mahmoud Al Quran",
            Speciality: "Specialist General Surgery",
            img: "/doctors/user.png"
        },
        {
            id: "16",
            name: "Dr. Agnel Pragasam",
            Speciality: "Specialist Sports Medicine",
            img: "/doctors/user.png"
        },
        {
            id: "17",
            name: "Dr. Ghassan Ali Ismael",
            Speciality: "Specialist Radiology",
            img: "/doctors/user.png"
        },
        {
            id: "18",
            name: "Dr. MOHAMMAD AL ZOUBI",
            Speciality: "Specialist Cardiology",
            img: "/doctors/user.png"
        },
        {
            id: "19",
            name: "Dr. Radi Jasem al hasan",
            Speciality: "Specialist Neurology",
            img: "/doctors/user.png"
        },
        {
            id: "20",
            name: "Dr. Kaes Al Ani",
            Speciality: "Consultant Cardiology",
            img: "/doctors/kaes.jpeg"
        },
        {
            id: "21",
            name: "Dr. Redwan Kalash",
            Speciality: "Specialist Otolaryngology",
            img: "/doctors/user.png"
        },
        {
            id: "22",
            name: "Dr. Nawras Aljijakli",
            Speciality: "Specialist Otolaryngology",
            img: "/doctors/user.png"
        },
        {
            id: "23",
            name: "Dr. Nandini Prasad",
            Speciality: "Specialist Otolaryngology",
            img: "/doctors/user.png"
        },
        {
            id: "24",
            name: "Dr. Muhammad Sibtain",
            Speciality: "Specialist Otolaryngology",
            img: "/doctors/user.png"
        },
        {
            id: "25",
            name: "Dr. Shiraz Adil Osman",
            Speciality: "Specialist Dermatology",
            img: "/doctors/shiraz.jpeg"
        },
        {
            id: "26",
            name: "Dr. Mohamed Magdy",
            Speciality: "Specialist Dermatology",
            img: "/doctors/user.png"
        },
        {
            id: "27",
            name: "Dr. Montasir Abdulwahab",
            Speciality: "Specialist Family Medicine",
            img: "/doctors/user.png"
        },
        {
            id: "28",
            name: "Dr. Nighet Abbas Hasanie",
            Speciality: "Specialist Family Medicine",
            img: "/doctors/user.png"
        },
        {
            id: "29",
            name: "Dr. Alaa Al Khayouti",
            Speciality: "Specialist Internal Medicine",
            img: "/doctors/alaa.jpeg"
        },
        {
            id: "30",
            name: "Dr. Ali Ahmad",
            Speciality: "Specialist Internal Medicine",
            img: "/doctors/user.png"
        },
        {
            id: "31",
            name: "Dr. Mohamed Saad",
            Speciality: "Specialist Pulmonology",
            img: "/doctors/user.png"
        },
        {
            id: "32",
            name: "Dr. Gawaher Mohamed",
            Speciality: "Specialist Obstetrics and Gynecology",
            img: "/doctors/gawaher.jpeg"
        },
        {
            id: "33",
            name: "Dr. Alaa Saifan",
            Speciality: "Specialist Psychiatry",
            img: "/doctors/user.png"
        },
        {
            id: "34",
            name: "Dr. Qasem J. M. Alqedra",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "35",
            name: "Dr. Atef Amin Zaghow",
            Speciality: "General Practitioner",
            img: "/doctors/zagow.jpeg"
        },
        {
            id: "36",
            name: "Dr. Mofeed Bahjat Atta",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "37",
            name: "Dr. Aroosa Tu Zain",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "38",
            name: "Dr. Asmaa Zakaria",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "39",
            name: "Dr. Afaf Gasim Shaikh",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "40",
            name: "Dr. Afsheen Rashid",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "41",
            name: "Dr. Elshimaa Ahmed",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "42",
            name: "Dr. Ahmed El Gamal",
            Speciality: "General Dentist",
            img: "/doctors/user.png"
        },
        {
            id: "43",
            name: "Dr. Ahmad Awad",
            Speciality: "General Dentist",
            img: "/doctors/awad.jpeg"
        },
        {
            id: "44",
            name: "Dr. Rasha Hussein",
            Speciality: "General Dentist",
            img: "/doctors/user.png"
        },
        {
            id: "45",
            name: "Dr. Ahmad Yahya",
            Speciality: "General Dentist",
            img: "/doctors/user.png"
        },
        {
            id: "46",
            name: "Dr. Mohammad Redwaen",
            Speciality: "General Dentist",
            img: "/doctors/user.png"
        },
        {
            id: "47",
            name: "Asma Ali",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "48",
            name: "Darsana K",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "49",
            name: "Priyanka R. Agravat",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "50",
            name: "Abraham Benshepherd",
            Speciality: "Physiotherapist",
            img: "/doctors/abraham.jpeg"
        },
        {
            id: "51",
            name: "Hadil Ahmed Arfat",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "52",
            name: "Vyshnavi Mandakathingal",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "53",
            name: "Joven Lim",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "54",
            name: "Arfad Ahamed",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "55",
            name: "Dr. Lakshmikanth",
            Speciality: "Specialist Prosthodontics",
            img: "/doctors/lakshmikanth.jpeg"
        },
        {
            id: "56",
            name: "Dr. Rashmi Deepak",
            Speciality: "Specialist Pediatric Dentistry",
            img: "/doctors/user.png"
        },
        {
            id: "57",
            name: "Dr. Mustafa Ashour",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "58",
            name: "Maitha Alazeezi",
            Speciality: "Clinical Dietician",
            img: "/doctors/user.png"
        },
        {
            id: "59",
            name: "Zubaida Alloush",
            Speciality: "Clinical Dietician",
            img: "/doctors/zubaida.jpeg"
        },
        {
            id: "60",
            name: "Mariam Mhd Salim",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "61",
            name: "Jayson D. Tutaan",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "62",
            name: "Richard John Velasco",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "63",
            name: "Baiju Neelakantan",
            Speciality: "Radiography Technician",
            img: "/doctors/user.png"
        },
        {
            id: "64",
            name: "Shamma Alnahdi",
            Speciality: "Radiography Technician",
            img: "/doctors/user.png"
        },
        {
            id: "65",
            name: "Dr. Sara Mutasim",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "66",
            name: "Fatima Hamed",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "67",
            name: "Chamandeep Surjit",
            Speciality: "Physiotherapist",
            img: "/doctors/user.png"
        },
        {
            id: "68",
            name: "Dr. Hanan Wafik",
            Speciality: "Specialist Obstetrics and Gynecology",
            img: "/doctors/wafik.jpeg"
        },
        {
            id: "69",
            name: "Dr. Heba Elsayed",
            Speciality: "Specialist Rheumatology",
            img: "/doctors/heba.jpeg"
        },
        {
            id: "70",
            name: "Dr. Baha Abusada",
            Speciality: "Specialist General Surgery",
            img: "/doctors/user.png"
        },
        {
            id: "71",
            name: "Dr. Ahmad Yousef",
            Speciality: "Specialist Obstetrics and Gynecology",
            img: "/doctors/yousef.jpeg"
        },
        {
            id: "72",
            name: "Dr. Marwa Hassan",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "73",
            name: "Dr. Anas Abu Amkra",
            Speciality: "General Dentist",
            Location: "AL Wagan",
            img: "/doctors/user.png"
        },
        {
            id: "74",
            name: "Dr. Hazem",
            Speciality: "General Dentist",
            Location: "AL Wagan",
            img: "/doctors/user.png"
        },
        {
            id: "75",
            name: "Iyas Mohammad",
            Speciality: "Radiography Technician",
            img: "/doctors/user.png"
        },
        {
            id: "76",
            name: "Dr. Malaz Hashim",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        },
        {
            id: "77",
            name: "Dr. Hind Ibraheem",
            Speciality: "Consultant Family Medicine",
            img: "/doctors/user.png"
        },
        {
            id: "78",
            name: "Dr. Abdelhameed Mohamed",
            Speciality: "General Practitioner",
            img: "/doctors/user.png"
        }

    ];
    const specialities = Array.from(new Set(allDoctors.map((doctor) => doctor.Speciality)));

    const filteredDoctors = allDoctors.filter((doctor) => {
        return (!selectedSpeciality || doctor.Speciality === selectedSpeciality) &&
            (selectedLanguages.length === 0 ||
                selectedLanguages.includes('All') ||
                selectedLanguages.some(language => doctor.languages.includes(language)));
    });

    
    

    return (
        <Modal show={show} onHide={handleClose} >

            <Modal.Header closeButton>

            </Modal.Header>
            <Modal.Body>
                <div className="container">
                    <div className="row">
                        <h2 className="fw-bold mb-4" style={{ textAlign: "center" }}>Make an appointment</h2>

                        {/* <div className="bg-holder bg-size" style={{ backgroundImage: 'url(assets/img/gallery/dot-bg.png)', backgroundPosition: 'bottom right', backgroundSize: 'auto' }}> */}
                        {/* </div> */}
                        {/*/.bg-holder*/}
                        {/* <div className="col-lg-6 z-index-2 mb-5"><img className="w-100" src="assets/img/gallery/appointment.png" alt="..." /></div> */}
                        <div className="col-lg-12 z-index-2">
                            <form className="row g-3">
                                <div className="col-md-6">
                                    <label className="visually-hidden" htmlFor="inputName">Name</label>
                                    <input className="form-control form-livedoc-control" id="inputName" type="text" placeholder="Name" />
                                </div>
                                <div className="col-md-6">
                                    <label className="visually-hidden" htmlFor="inputPhone">Phone</label>
                                    <input className="form-control form-livedoc-control" id="inputPhone" type="text" placeholder="Phone" />
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label visually-hidden" htmlFor="inputCategory">Category</label>
                                    <select onChange={handleSpecialityChange} className="form-select" id="inputCategory">
                                        <option selected="selected">Speciality</option>
                                        {specialities.map((d, index) => (
                                            <option>{d}</option>
                                        ))}
                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label visually-hidden" htmlFor="inputCategory">Category</label>
                                    <select  className="form-select" id="inputCategory">
                                        <option selected="selected">Doctor</option>
                                        {filteredDoctors.map((d, index) => (
                                            <option>{d.name}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <label className="form-label visually-hidden" htmlFor="inputEmail">Email</label>
                                    <input className="form-control form-livedoc-control" id="inputEmail" type="email" placeholder="Email" />
                                </div>
                                <div className="col-md-12">
                                    <label className="form-label visually-hidden" htmlFor="validationTextarea">Message</label>
                                    <textarea className="form-control form-livedoc-control" id="validationTextarea" placeholder="Message" style={{ height: '250px' }} required="required"></textarea>
                                </div>
                                <div className="col-12">
                                    <div className="d-grid">
                                        <button className="btn btn-primary rounded-pill" type="submit">Confirm</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </Modal.Body>

        </Modal>
    )
}
export default Appointment